<template>
  <v-container id="genetic-profile" fluid tag="section" class="pt-0">
    <fondo-custom></fondo-custom>

    <!-- TITULO  -->
    <v-row
      justify="start"
      justify-sm="center"
      class="sticky background-gradient top-title z-index-7"
    >
      <page-top-title>
        <template v-slot:toptitle-icon>mdi-dna</template>
        <template v-slot:toptitle-text
          >{{ $t("perfil farmacogenetico") }}
        </template>
      </page-top-title>
      <v-btn
        v-if="gxGeneResults.length"
        @click="downloadReport"
        color="primary"
        :disabled="overlay"
        :loading="overlay"
      >
        <v-icon class="mt-n1 ml-n2 mr-2">mdi-download</v-icon>
        {{ $t("descargar_informe") }}
      </v-btn>
      <div v-else>
        <v-btn
          @click="loadDemoData"
          color="primary"
          :disabled="overlay"
          :loading="overlay"
        >
          {{ $t("cargar_demo") }}
        </v-btn>
        <v-btn
          @click="goToActivationForm"
          color="info"
          :disabled="overlay"
          :loading="overlay"
        >
          {{ $t("activar_cuenta") }}
        </v-btn>
      </div>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="10">
        <!-- FILTROS -->
        <v-row class="sticky filters z-index-99">
          <v-container class="" fluid>
            <v-card class="pa-0" rounded="lg" elevation="12">
              <v-card-title
                class="solid-heading rounded-lg py-0 px-4 px-sm-10  mb-0"
              >
                <drug-input-search @moreFilters="moreFilters = !moreFilters" />
              </v-card-title>
            </v-card>
          </v-container>
        </v-row>

        <!-- RESULTADOS -->
        <v-row
          v-for="geneClass in Object.keys(geneClasses)"
          :key="geneClass"
          class="gradient-bucle"
        >
          <v-container fluid>
            <v-card class="my-2 pa-0" rounded="lg">
              <v-card-title class="solid-heading pt-2 rounded-lg">
                <v-col cols="1" class="">
                  <h5 class="text-h3 white--text">
                    {{ geneClasses[geneClass].idx }}
                  </h5>
                </v-col>
                <v-col>
                  <p class="white--text">{{ $t("grupo_de_genes") }}</p>
                  <div
                    class="card-title text-h3 mt-n3 font-weight-light white--text"
                  >
                    {{ $t(geneClass) }}
                  </div>
                </v-col>
              </v-card-title>

              <v-container fluid justify="space-between">
                <v-list class="gene-container" rounded>
                  <gene-card
                    v-for="gene in filteredPgxGenesByCategory(geneClass)"
                    :key="gene.id"
                    :gene="gene"
                  />
                </v-list>
              </v-container>
              <v-card-actions>
                <v-divider></v-divider>
                <v-btn
                  color="primary"
                  outlined
                  @click="
                    geneClasses[geneClass].show = !geneClasses[geneClass].show
                  "
                  rounded
                >
                  {{ $t("leyenda") }}
                  <v-icon>{{
                    show ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="geneClasses[geneClass].show">
                  <v-card-text class="pt-0">
                    <v-container class="pt-0">
                      <v-row>
                        <v-col
                          v-for="color in geneClasses[geneClass].colors"
                          :key="color"
                          cols="12"
                          md="6"
                          class="d-flex"
                        >
                          <v-icon
                            large
                            :color="color"
                            class="align-self-start mt-n2 mr-2"
                            >mdi-circle</v-icon
                          >
                          {{ $t(`legend_${geneClass}_${color}`) }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </v-container>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Api from "@/services/Strapi.js";
import { mapGetters, mapActions } from "vuex";
import GeneCard from "@/components/mylogy/GeneCard.vue";
import FondoCustom from "@/components/mylogy/FondoCustom.vue";
import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";
import DrugInputSearch from "@/components/mylogy/pgx_results/DrugInputSearch.vue";

var slugify = require("slugify");

export default {
  components: {
    GeneCard,
    FondoCustom,
    PageTopTitle,
    DrugInputSearch
  },
  data() {
    return {
      overlay: false,
      moreFilters: false,
      sortedGenes: [],
      show: false,
      genes: [],
      colors: ["gray", "#b0e0e6", "green", "yellow", "orange", "red"],
      geneClasses: {
        "Phase I Metabolism": {
          idx: 1,
          show: true,
          colors: ["gray", "green", "yellow", "orange", "red"]
        },
        "Phase II Metabolism": {
          idx: 2,
          show: true,
          colors: ["gray", "green", "yellow", "orange", "red"]
        },
        Transporter: {
          idx: 3,
          show: true,
          colors: ["gray", "green", "yellow", "red"]
        },
        Receptor: {
          idx: 4,
          show: true,
          colors: ["gray", "green", "yellow", "red"]
        },
        Pathogenic: {
          idx: 5,
          show: true,
          colors: ["gray", "green", "yellow", "red"]
        }
      },
      search: null,
      selectedPanel: "CARD60",
      dialog: false
    };
  },
  computed: {
    ...mapGetters("pgxGenes", ["pgxGenesByCategory", "pgxGenesSortedSymbols"]),
    ...mapGetters("gxSample", ["sampleNumber"]),
    ...mapGetters("gxGeneResults", ["gxGeneResults"])
  },
  mounted() {},
  methods: {
    ...mapActions("gxSample", ["getSampleData"]),
    goToActivationForm() {
      window.open("https://mylogygenomics.com/activacion-cuenta/", "_blank");
    },
    async loadDemoData() {
      await this.getSampleData("626660ce92acf0006e695668");
    },
    downloadReport() {
      let self = this;
      Api.get(
        `/gx-samples/${this.sampleNumber}/report/${this.$i18n.locale}`
      ).then(resp => {
        fetch(resp.data.downloadLink).then(async resp => {
          if (resp.status == 404) {
            const res = await this.$dialog.error({
              actions: {
                accept: {
                  text: self.$t("Aceptar")
                },
                cancel: {
                  text: self.$t("cancelar")
                }
              },
              text: self.$t(
                "Es necesario actualizar su Perfil Farmacogenetico"
              ),
              title: self.$t("No se ha encontrado el documento")
            });
            if (res == "accept") {
              window.open(
                "https://mylogygenomics.com/producto/ampliacion-perfil-farmacogenetico/",
                "_blank"
              );
            }
          } else {
            const blob = await resp.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = "report.pdf";

            self.$ga.event("webapp", "download_report", self.sampleNumber, 1);

            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          }
        });
      });
    },

    filteredPgxGenesByCategory(geneClass) {
      let self = this;
      if (!this.search) {
        return this.pgxGenesByCategory(geneClass);
      }
      return this.pgxGenesByCategory(geneClass).filter(g =>
        g.symbol.toLowerCase().includes(self.search.toLowerCase())
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.gene-container {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 10px;
  row-gap: 0px;
  justify-items: center;
}
</style>

<style lang="scss">
.text-h4 .v-badge__badge {
  width: 25px;
  height: 25px;
  border-radius: 20px;
}

fieldset {
  border-color: #4d2cb9 !important;
}
.css-tprr8g {
  height: 56px !important;
  border-radius: 50px !important;
  background: white !important;
}
.css-1ee1q46,
.css-l5274x {
  top: 15px !important;
}
.suggestions {
  position: absolute;
  z-index: 3;
  box-shadow: 0 0 10px #00000055 !important;
  //border: solid 1px#411285 !important;
  overflow-y: scroll;
  max-height: 400px;

  ul {
    list-style: none;
    padding-left: 0;
  }
  li {
    border-bottom: solid 1px #f0f0f0;
    transition: 0.2s;
    &:hover {
      background: #f0f0f0;
    }
    > .row {
      align-items: center;
    }
  }
  .v-icon {
    height: 1rem;
  }
}

.sticky.filters {
  top: 100px;
}

$bg-color: #7b29ff;
@for $i from 1 through 5 {
  .gradient-bucle.row:nth-child(#{$i}) .solid-heading {
    $ptg: percentage($i/5);
    background: mix(#100029, $bg-color, $ptg);
  }
}
</style>
